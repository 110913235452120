import { Card, Divider, Select, Tabs, Tooltip } from "antd";
import { Button } from "antd";
import { Image, message } from "antd";
import { useRef, useState, useEffect, useMemo } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { FaClipboard, FaStopCircle } from "react-icons/fa";
import Stars from "../assets/stars.svg";
import { useSearchParams } from "react-router-dom";
import { FeedbackModal } from "./modals/FeedbackModal";
import { RateLecturerModal } from "./modals/RateLecturerModal";
import ShareToUsersModal from "./modals/ShareToUsersModal";
import SummaryModal from "./modals/SummaryModal";
import WikipidiaSummaryOnboarding from "./modals/WikipidiaSummaryOnboarding";
import { useGetRecap } from "../hooks/recap/recap";
import { useGetYoutubeVideo } from "../hooks/otherhooks";
import { isEqual } from "../context/utils";
import { ImQuotesLeft } from "react-icons/im";
import { IoCopyOutline } from "react-icons/io5";
import Speech from "react-text-to-speech";
import { PiSpeakerHighFill } from "react-icons/pi";

export const RecapPageComponent = ({
  data: getRecapData,
  load: getRecapLoad,
  payload,
  setPayload,
  youTubeData,
  showFeedBack,
  setShowFeedBack,
  showRateLecturer,
  setShowRateLecturer,
  openShare,
  setOpenShare,
  pdfRef,
  youtubeVideos = [],
}: any) => {
  const [params] = useSearchParams();
  const [activeTab, setActiveTab] = useState("mini_reflections");
  // const pdfRef = useRef<HTMLDivElement>(null);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [miniViewList, setMiniViewList] = useState<number[]>([]);
  const [openWiki, setOpenWiki] = useState(false);
  const id = params.get("id");
  //   const [showFeedBack, setShowFeedBack] = useState(false);
  //   const [showRateLecturer, setShowRateLecturer] = useState(false);
  //   const [openShare, setOpenShare] = useState(false);
  const [selected, setSelected] = useState<string>("");

  const tabs = useMemo(
    () =>
      [
        {
          title: "Summary",
          label: "Mini Recaps",
          key: "mini_reflections",
          description:
            "Tailored for speedy understanding, it pinpoints essential details instantly.",
        },
        {
          title: "Overall",
          key: "reflections",
          extra: "bullet_points",
          label: "Overall Recaps",
          description: "Uncover a comprehensive understanding of your content.",
        },
        {
          title: "Transcription",
          label: "Transcription",
          key: "full_transcriptions",
          description: "Your original file converted to transcript.",
        },
      ].filter(({ key }) =>
        Object.keys(getRecapData?.data?.recaps || {})?.includes(key)
      ),
    [getRecapData]
  );

  const currentTab = useMemo(
    () => tabs.find(({ key }) => isEqual(key, activeTab)),
    [activeTab, tabs]
  );

  const toPdf = () => {
    const input = pdfRef.current;
    if (input) {
      (html2canvas as any)(input, { scale: 2 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height],
        });

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        let contentHeight = pdfHeight;
        let position = 0;

        while (contentHeight > 0) {
          pdf.addImage(imgData, "PNG", 0, position, pdfWidth, pdfHeight);
          contentHeight -= pdf.internal.pageSize.getHeight();
          position -= pdf.internal.pageSize.getHeight();

          if (contentHeight > 0) {
            pdf.addPage();
          }
        }

        pdf.save(titles + ".pdf");
      });
    }
  };

  const shareToUser = () => {
    setOpenShare(true);
  };

  let selectionTimeout: string | number | NodeJS.Timeout | undefined;

  const handleTextSelection = () => {
    clearTimeout(selectionTimeout); // Clear the timeout if it's already set
    const selection = window.getSelection();
    const page = document.querySelector(`.page_canvas`);

    if (
      selection !== null &&
      selection.toString().length > 0 &&
      page?.contains(selection.anchorNode)
    ) {
      // Set a timeout to check if the user has finished selecting after 500ms
      selectionTimeout = setTimeout(() => {
        const selectedText = selection.toString();
        setSelected(selectedText);
        setShowSummaryModal(true);
      }, 500);
    }
  };

  useEffect(() => {
    setOpenWiki(true);
    // Add event listener for selectionchange event
    document.addEventListener("selectionchange", handleTextSelection);

    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener("selectionchange", handleTextSelection);
    };
  }, []);

  useEffect(() => {
    if (getRecapData?.data?.recaps["mini_reflections"])
      setActiveTab("mini_reflections");
    else if (getRecapData?.data?.recaps["reflections"])
      setActiveTab("reflections");
    else if (getRecapData?.data?.recaps["full_transcriptions"])
      setActiveTab("full_transcriptions");
  }, [getRecapData]);

  const titles = useMemo(() => {
    if (activeTab === "mini_reflections")
      return `${getRecapData?.data?.name} - Mini Recaps`;
    else if (activeTab === "reflections")
      return `${getRecapData?.data?.name} - Overall Recaps`;
    else if (activeTab === "full_transcriptions")
      return `${getRecapData?.data?.name} - Transcription`;
  }, [activeTab, getRecapData?.data?.name]);

  const {
    isMiniRecapTab,
    miniRecapObj,
    currentRecapData,
    currentRecapExtra,
    miniRecapCount,
  } = useMemo(() => {
    const currentRecapExtra =
      getRecapData?.data?.recaps?.[currentTab?.extra as any];
    const miniRecapData = getRecapData?.data?.recaps?.mini_reflections;
    const currentRecapData = getRecapData?.data?.recaps?.[activeTab];
    const isMiniObject = typeof miniRecapData === "object";
    const isMiniRecapTab = activeTab === "mini_reflections";
    const miniRecapObj = isMiniObject
      ? miniRecapData
      : {
          CHUNK_REFLECTION_0: miniRecapData,
          CHUNK_BULLET_POINT_0: [],
          CHUNK_STATEMENT_0: "",
        };
    const miniRecapArr = Object.entries(miniRecapObj || {});
    const miniRecapCount = miniRecapArr?.filter(([key]) =>
      key?.toLowerCase()?.includes("chunk_statement")
    )?.length;
    return {
      miniRecapArr,
      miniRecapObj,
      miniRecapCount,
      currentRecapData,
      isMiniRecapTab,
      currentRecapExtra,
    };
  }, [getRecapData, currentTab, activeTab]);

  const handleCopy = () => {
    message.success("Copied to clipboard");
    navigator.clipboard.writeText(`
      ${currentRecapData ? currentRecapData : ""}.
      ${currentRecapExtra ? currentRecapExtra?.join(", ") : ""}.
    `);
  };

  return (
    <div className="flex flex-col items-center md:py-5 space-y-5 w-full">
      <div className="w-full flex flex-col gap-5 px-5 lg:px-10">
        <Tabs
          items={tabs}
          activeKey={activeTab}
          onChange={setActiveTab}
          defaultActiveKey={activeTab}
          tabBarExtraContent={{ right: <Select
            size="large"
            value={payload?.grade_level}
            placeholder="Select Difficulty"
            onChange={grade_level => setPayload?.({...payload, grade_level})}
            options={[
              { label: "Advanced", value: "Above" },
              { label: "Moderate", value: "On" },
              { label: "Easy", value: "Below" },
            ]}
          /> }}
        />

        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5 page_canvas">
          <div className="md:col-span-2 space-y-5">
            {isMiniRecapTab ? (
              Array.from(Array(miniRecapCount).keys())?.map((d) => {
                const isViewMore = miniViewList?.includes(d);
                const point = miniRecapObj?.[`CHUNK_BULLET_POINT_${d}`];
                const statement = miniRecapObj?.[`CHUNK_STATEMENT_${d}`];
                const reflection = miniRecapObj?.[`CHUNK_REFLECTION_${d}`];
                const viewMore = () => setMiniViewList([...miniViewList, d]);
                const viewLess = () =>
                  setMiniViewList(miniViewList?.filter((b) => !isEqual(d, b)));
                const viewText = isViewMore
                  ? "Hide Full Chunk"
                  : "Read Full Chunk";
                const handleView = () => (isViewMore ? viewLess() : viewMore());
                return (
                  <Card
                    key={d}
                    title={
                      <div className="">
                        <ImQuotesLeft className="text-3xl text-[#D1D1D1]" />
                        <p className="whitespace-normal p-5 pt-0 text-sm font-medium text-[#414141] !line-clamp-1">
                          {reflection}
                        </p>
                      </div>
                    }
                    className="shadow-md"
                    headStyle={{ background: "#F5F5F5" }}
                  >
                    <div className="!space-y-5">
                      <p
                        className={`text-sm font-normal text-[#1B1B1B] ${
                          !isViewMore && "line-clamp-3"
                        }`}
                      >
                        {statement}
                      </p>
                      <div hidden={!isViewMore} className="space-y-5">
                        <p className="text-sm font-normal text-[#1B1B1B]">
                          {reflection}
                        </p>
                        <div hidden={!point?.length} className="space-y-2">
                          <p className="text-sm font-medium text-[#1B1B1B]">
                            Key Points
                          </p>
                          <ul className="!list-disc px-5 !text-sm">
                            {(point || [])?.map((p: string) => (
                              <li>{p}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                      <Button
                        type="text"
                        onClick={handleView}
                        className="text-primary !px-0"
                      >
                        {viewText}
                      </Button>
                    </div>
                  </Card>
                );
              })
            ) : (
              <Card className="shadow-md">
                <div className="!space-y-5">
                  <p className="text-sm font-normal text-[#1B1B1B]">
                    {currentRecapData}
                  </p>
                  <div
                    hidden={!currentRecapExtra?.length}
                    className="space-y-2"
                  >
                    <p className="text-sm font-medium text-[#1B1B1B]">
                      Key Points
                    </p>
                    <ul className="!list-disc px-5 !text-sm">
                      {(currentRecapExtra || [])?.map((p: string) => (
                        <li>{p}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex items-center gap-5">
                    <Button
                      type="text"
                      size="large"
                      className="!px-0"
                      onClick={handleCopy}
                      icon={<IoCopyOutline className="text-xl" />}
                    />
                    <Speech text={currentRecapData?.concat(" ", (currentRecapExtra || [])?.join(" "))}>
                      {({ speechStatus, start, stop }) => (isEqual(speechStatus, "started") ? (
                        <FaStopCircle onClick={() => stop?.()} className='text-primary !text-2xl cursor-pointer' />
                      ) : (
                        <Tooltip title="Click to listen.">
                          <PiSpeakerHighFill onClick={() => start?.()} className='text-primary !text-2xl cursor-pointer' />
                        </Tooltip>
                      ))}
                    </Speech>
                  </div>
                </div>
              </Card>
            )}
          </div>
          <div
            className="w-full cursor-pointer"
            onClick={() => {
              window.open(
                `https://www.youtube.com/watch?v=${
                  getRecapData?.data?.recaps?.youtube_videos?.length > 0
                    ? getRecapData?.data?.recaps?.youtube_videos[0]?.video_id
                    : youTubeData?.items[0].id.videoId
                }`,
                "_blank"
              );
            }}
          >
            <div className="w-full rounded-[16px]">
              <Image
                src={
                  getRecapData?.data?.recaps?.youtube_videos?.length > 0
                    ? getRecapData?.data?.recaps?.youtube_videos[0]?.thumbnail
                    : youTubeData?.items[0].snippet.thumbnails.high.url
                }
                alt="thumbnail"
                preview={false}
                className="rounded-t-[16px]"
              />
              <div className="border-x-[1px] border-l-[1px] border-b-[1px] border-[#E5E5E5] p-4 flex w-full flex-col gap-2 rounded-b-[8px]">
                <div className="w-fit p-2 flex flex-row gap-1 rounded-[8px] bg-[#EFEFEF] ">
                  <Image src={Stars} alt="stars" className="w-4 h-4" />
                  <span className="text-[12px] leading-[18px] font-medium text-[#676767]">
                    Recommended
                  </span>
                </div>
                <span className="font-medium text-[14px] leading-[22px]">
                  {getRecapData?.data?.recaps?.youtube_videos?.length > 0
                    ? getRecapData?.data?.recaps?.youtube_videos[0]?.title
                    : youTubeData?.items[0].snippet.title}
                </span>
                <span className="text-[12px] leading-[18px] font-medium text-[#676767]">
                  {getRecapData?.data?.recaps?.youtube_videos?.length > 0
                    ? getRecapData?.data?.recaps?.youtube_videos[0]?.date
                        ?.split("T")
                        .join(" ")
                        .split(".")[0]
                    : youTubeData?.items[0].snippet.publishedAt
                        .split("T")
                        .join(" ")
                        .split(".")[0] || ""}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <aside
        ref={pdfRef}
        className="w-full max-w-[596px] absolute -top-[200vh] -left-full"
      >
        <div className="h-9 bg-[#4970fc]" />
        <div className="">
          <div className="flex gap-3 items-center pt-4 px-4">
            <img
              src="/icon.png"
              alt=""
              className="w-[60px] h-[60px] object-contain"
            />
            <p className="font-bold text-2xl">{titles}</p>
          </div>
          <Divider />
        </div>
        <div className="px-4 pb-6">
          <div className="text-base font-normal text-[#1B1B1B] text-cente leading-6">
            {/* <p>{getRecapData?.data?.recaps?.[activeTab]}</p> */}
          </div>
          <div
            className="space-y-3"
            hidden={!currentTab?.extra || getRecapLoad}
          >
            <p className="text-sm font-medium text-dark">Key Points</p>
            <ul className="text-sm text-dark !list-disc px-5">
              {/* {getRecapData?.data?.recaps?.[currentTab?.extra as any]?.map(
                  (d: any) => (
                    <li>{d}</li>
                  )
                )} */}
            </ul>
          </div>
        </div>
      </aside>

      <FeedbackModal
        showModal={showFeedBack}
        setShowModal={setShowFeedBack}
        featureId={id}
        feature="recaps"
      />

      <ShareToUsersModal
        open={openShare}
        setOpenShare={setOpenShare}
        type="recaps"
      />

      <RateLecturerModal
        showModal={showRateLecturer}
        setShowModal={setShowRateLecturer}
        featureId={id}
        feature="recaps"
      />
      <WikipidiaSummaryOnboarding
        openSuccess={openWiki}
        setOpenSuccess={setOpenWiki}
      />
      {showSummaryModal ? (
        <SummaryModal
          query={selected}
          openSuccess={showSummaryModal}
          setOpenSuccess={setShowSummaryModal}
        />
      ) : null}
    </div>
  );
};
