/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Avatar, Button, Divider, Form, Image, Input, Spin, Tooltip } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { handleCopy, isEqual } from "../../../../context/utils";
import Loading from "../../../../components/loading";
import icon from "../../../../assets/icons/icon.png";
import { RiArrowGoBackFill } from "react-icons/ri";
import {
  useGetDiscuss,
  usePostDiscussChat,
  usePostDiscussReact,
} from "../../../../hooks/discuss/discuss";
import DiscussIcon from "../../../../assets/icons/discussicon";
import moment from "moment";
import { useMemo, useState } from "react";
import { FaArrowDownLong } from "react-icons/fa6";
import { BiDislike, BiErrorCircle, BiLike, BiLoader } from "react-icons/bi";
import { GrSend } from "react-icons/gr";
import { RxCopy } from "react-icons/rx";
import { useRecoilValue } from "recoil";
import authAtom from "../../../../atoms/auth/auth.atom";
import ShareToUsersModal from "../../../../components/modals/ShareToUsersModal";
import Speech from "react-text-to-speech";
import { FaStopCircle } from "react-icons/fa";
import { PiSpeakerHighFill } from "react-icons/pi";

function DiscussSection() {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { user } = useRecoilValue(authAtom);
  const [chats, setChats] = useState<any>([]);
  const [openShare, setOpenShare] = useState(false);
  const handleClear = () => setChats([]);
  const contentId = params.get("content");
  const materialId = params.get("id");
  const id = contentId || materialId;

  const chatBox = document.getElementById("chat-box");
  const handleChatScroll = () =>
    setTimeout(
      () => chatBox?.scroll({ top: chatBox?.scrollHeight, behavior: "smooth" }),
      500
    );

  const [form] = Form.useForm();
  const goBack = () => navigate("/discuss");

  const {
    data: getDiscussData,
    refetch: getDiscussFetch,
    isLoading: getDiscussLoad,
    isFetching: getDiscissFetching,
  } = useGetDiscuss(
    id!,
    () => {
      handleChatScroll();
      handleClear();
    },
    handleClear
  );

  const memoData: any = useMemo(
    () => [...(getDiscussData?.data?.chats || []), ...chats],
    [getDiscussData, chats]
  );

  const {
    isError: postDisChatErr,
    mutate: postDisChatAction,
    isLoading: postDisChatLoad,
  } = usePostDiscussChat(getDiscussFetch);

  const { mutate: postDisReactAction, isLoading: postDisReactLoad } =
    usePostDiscussReact(id!, getDiscussFetch);

  const handleSubmit = (d: any) => {
    const payload = { id, ...d };
    setChats([...chats, payload]);
    postDisChatAction(payload);
    handleChatScroll();
    form.resetFields();
  };

  const handleReact = (chat_index: number, reaction: string) =>
    postDisReactAction({
      chat_index,
      reaction,
    });

  if (getDiscussLoad) return <Loading />;
  return (
    <Spin spinning={getDiscussLoad}>
      <div className="w-full min-h-full flex flex-col md:justify-between items-center md:pt-5 relative">
        <div
          hidden={Boolean(!memoData?.length)}
          className="w-full h-[50vh] md:h-[60vh] overflow-y-auto space-y-10 p-5"
          id="chat-box"
        >
          {memoData?.map((d: any, idx: number) => {
            const isAI = isEqual(d?.sender, "nurovant");
            const img = isAI ? icon : user?.info?.profile_img;
            return (
              <div
                className={`w-full flex gap-3 ${!isAI && "flex-row-reverse"}`}
              >
                <div className="w-[10%] md:w-[5%] flex flex-col items-center">
                  <Avatar alt="nurovant" src={img} />
                  <div hidden={!isAI}>
                    <Speech text={d?.message}>
                      {({ speechStatus, start, stop }) => (isEqual(speechStatus, "started") ? (
                        <FaStopCircle onClick={() => stop?.()} className='text-[#B3B3B3] hover:text-primary !text-xl cursor-pointer' />
                      ) : (
                        <Tooltip title="Click to listen.">
                          <PiSpeakerHighFill onClick={() => start?.()} className='text-[#B3B3B3] hover:text-primary !text-xl cursor-pointer' />
                        </Tooltip>
                      ))}
                    </Speech>
                  </div>
                  <Button
                    className="!bg-transparent !p-0 !m-0 !text-[#B3B3B3] hover:!text-primary disabled:!text-primary"
                    onClick={() => handleCopy(d?.message)}
                    icon={<RxCopy className="text-xl" />}
                    hidden={!isAI}
                    type="text"
                  />
                  <Button
                    className="!bg-transparent !p-0 !m-0 !text-[#B3B3B3] hover:!text-primary disabled:!text-primary"
                    onClick={() => handleReact(idx, "true")}
                    disabled={isEqual(d?.reaction, "true")}
                    icon={<BiLike className="text-xl" />}
                    loading={postDisReactLoad}
                    hidden={!isAI}
                    type="text"
                  />
                  <Button
                    className="!bg-transparent !p-0 !m-0 !text-[#B3B3B3] hover:!text-primary disabled:!text-primary"
                    onClick={() => handleReact(idx, "false")}
                    disabled={isEqual(d?.reaction, "false")}
                    icon={<BiDislike className="text-xl" />}
                    loading={postDisReactLoad}
                    hidden={!isAI}
                    type="text"
                  />
                </div>
                <div className="max-w-[80%] md:max-w-[70%] space-y-3">
                  <p
                    hidden={!isAI}
                    className="p-3 bg-[#F9F9F9] rounded-2xl !rounded-bl-none text-sm font-medium text-dark"
                  >
                    {d?.message}
                  </p>
                  <p
                    hidden={isAI}
                    className="p-3 bg-[#DBE2FE] rounded-2xl text-sm font-medium text-dark"
                  >
                    <p className="flex justify-between items-center gap-3">
                      <span>{d?.message || d?.question}</span>
                      {d?.question && postDisChatLoad && (
                        <BiLoader className="animate-spin" />
                      )}
                      {d?.question && postDisChatErr && (
                        <BiErrorCircle className="text-red" />
                      )}
                    </p>
                  </p>
                  {/* <p className='text-xs font-medium txet-[#838382]'>{moment(d?.date).startOf('hour').fromNow()}</p> */}
                </div>
              </div>
            );
          })}
        </div>

        <div hidden={Boolean(memoData?.length)} className="w-full h-full">
          <div className="w-full h-full flex flex-col justify-center items-center gap-3 text-center">
            <DiscussIcon bg="#4970FC" color="#D6FFFA" />
            <p className="text-xl font-normal text-[#646462]">
              Hey there, I’m here to assist you with any
              <br /> questions you have about the content of the
              <br /> document
            </p>
            <div className="flex justify-center items-center gap-3">
              {[
                "What's this material about?",
                "Summary of this material",
                "Keywords regarding this material",
              ].map((question) => (
                <Button
                  onClick={() => handleSubmit({ question })}
                  className="text-primary bg-[#C2CFFE] !rounded-2xl !h-[40px]"
                  type="primary"
                  size="small"
                >
                  {question}
                </Button>
              ))}
            </div>
          </div>
        </div>

        <Form form={form} onFinish={handleSubmit} className="!w-full px-5">
          <Form.Item name="question">
            <Input
              required
              size="large"
              placeholder="Type a message"
              className="!h-[50px] !rounded-2xl"
              suffix={
                <Button
                  className="!bg-transparent !p-0 !m-0 !text-primary"
                  icon={<GrSend className="text-2xl" />}
                  loading={postDisChatLoad}
                  htmlType="submit"
                  shape="round"
                  size="large"
                  type="text"
                />
              }
            />
          </Form.Item>
        </Form>

        <Button
          className="w-auto bg-primary !fixed !bottom-28 right-5 md:!right-0 md:!left-0 md:!mx-auto"
          hidden={Boolean(!memoData?.length)}
          loading={getDiscissFetching}
          onClick={handleChatScroll}
          icon={<FaArrowDownLong />}
          shape="circle"
          type="primary"
        />
        <ShareToUsersModal
          open={openShare}
          setOpenShare={setOpenShare}
          type="discussion"
        />
      </div>
    </Spin>
  );
}

export default DiscussSection;
