import { useLocation, useSearchParams } from "react-router-dom";
import { notification } from "antd";
import { useMutation, useQuery } from "react-query";
import {
  deleteRequest,
  getRequest,
  postRequest,
} from "../../context/requestTypes";
import { useContext } from "react";
import { AxiosContext } from "../../context/AxiosContext";
import { AxiosInstance } from "axios";
import { useNavigate } from "react-router";
import { checkOddValue, handleObjToParam } from "../../context/utils";
import { analytics } from "../../firebaseAuth/config";
import { logEvent } from "firebase/analytics";

export function useGetAllQuiz(params?: any) {
  const url = "/api_backend/quiz/";
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:all_quiz"],
    () => getRequest(axios as unknown as AxiosInstance, url, params),
    {
      onError: (error: any) =>
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        }),
    }
  );
}

export function useGetQuiz(id: string, params?: any) {
  const url = "/api_backend/quiz/";
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:single_quiz", id, params],
    () => getRequest(axios as unknown as AxiosInstance, url + id + handleObjToParam(params)),
    {
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        if (error?.message?.includes("not exist")) {
        } else {
          notification.error({
            message: "Error!",
            description: error?.message
              ? Object.entries(error?.errors || { key: [error?.message] })
                  ?.map(([, value]) => (value as any)?.join(", "))
                  ?.join(", ")
              : "something went wrong please check internet connection.",
          });
        }
      },
      enabled: Boolean(id),
    }
  );
}

export function useFetchLectureQuiz(id: string, enabled?: boolean) {
  const url = "/api_backend/quiz/";
  const navigate = useNavigate();
  const location = useLocation();
  const axios = useContext(AxiosContext);
  return useQuery(
    ["get:single_quiz", id],
    () => getRequest(axios as unknown as AxiosInstance, url + id),

    {
      enabled: Boolean(enabled && !checkOddValue(id)),
      onError: (error: any) => {
        if (error?.message?.includes("authenticated")) {
          sessionStorage.setItem("fallback", JSON.stringify(location));
          navigate("/auth");
        }
        if (error?.message?.includes("not exist")) {
        } else {
          notification.error({
            message: "Error!",
            description: error?.message
              ? Object.entries(error?.errors || { key: [error?.message] })
                  ?.map(([, value]) => (value as any)?.join(", "))
                  ?.join(", ")
              : "something went wrong please check internet connection.",
          });
        }
      },
    }
  );
}

export function usePostQuiz(successAction?: any, errorAction?: any) {
  const [params, setParams] = useSearchParams();
  const activeAction = params.get("action");

  const url = "/api_backend/quiz/create";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.(response);
        logEvent(analytics, "create_quiz");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
        // setParams({ action: activeAction, id: response?.data?._id } as {
        //   action: string;
        //   id: string;
        // });
      },
      onError: (error: any) => {
        errorAction?.();
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function usePostQuizAnswer(successAction?: any) {
  const url = "/api_backend/quiz/submit_quiz";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (payload: any) =>
      postRequest(axios as unknown as AxiosInstance, url, payload),
    {
      onSuccess: (response: any) => {
        successAction?.();
        logEvent(analytics, "submit_quiz");
        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}

export function useDeleteQuiz(successAction?: any) {
  const url = "/api_backend/quiz/";
  const axios = useContext(AxiosContext);
  return useMutation(
    async (id: any) =>
      deleteRequest(axios as unknown as AxiosInstance, url + id),
    {
      onSuccess: (response: any) => {
        successAction?.();
        logEvent(analytics, "delete_quiz");

        notification.success({
          message: "Success!",
          description: response?.message || "action successful.",
        });
      },
      onError: (error: any) => {
        notification.error({
          message: "Error!",
          description: error?.message
            ? Object.entries(error?.errors || { key: [error?.message] })
                ?.map(([, value]) => (value as any)?.join(", "))
                ?.join(", ")
            : "something went wrong please check internet connection.",
        });
      },
    }
  );
}
