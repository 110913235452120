import { message, notification } from "antd";
import * as FileSaver from "file-saver";
import { RecordRTCPromisesHandler } from "recordrtc";
import * as XLSX from "xlsx";
import { MimeType } from "../constants";
import { useRecoilState } from "recoil";
import { audRecAtom } from "../atoms/others/others.atom";

export const formatNumber = (num: number | string | null) => {
  if (num !== undefined) {
    return parseFloat(Number(num).toFixed(2))
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  return "0";
};

export const formatCurrency = (value: number, currency?: string) =>
  new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currency || "NGN",
  })
    .format(value)
    .replace(".00", "");

export const handleDataExport = (name: string, parseData: any) => {
  const ws = XLSX.utils.json_to_sheet(parseData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
  });
  FileSaver.saveAs(data, `zero-${name}.xlsx`);
};

export const handleDataReduce = (data: any, key: string) => {
  return data?.reduce((a: any, b: any) => Number(a) + Number(b?.[key] || b), 0);
};

export const handleObj = (data: any) => Object.fromEntries(Object.entries(data || {})?.filter(([key, val]) => val));

export const handleCopy = (content: string) => {
  message.success("Copied to clipboard");
  navigator.clipboard.writeText(content);
};

export const isEqual = (data: any, key: any) => data === key;

export const extractAvatar = (name: string) => {
  const splitName = name?.split(" ");
  if (splitName.length === 1) return splitName[0].charAt(0).toUpperCase();
  const firstLetter = splitName?.[0]?.charAt(0);
  const secondLetter = splitName?.[1]?.charAt(0);
  return `${firstLetter}${secondLetter}`;
};

export const handleHash = (data: string) => {
  const mailName = (data || "")?.slice(0, (data || "")?.indexOf("@"));
  const mailProvider = (data || "")?.slice((data || "")?.indexOf("@"));
  const mailNameCut = mailName.slice(0, Math.floor(mailName.length / 2));
  return mailNameCut.padEnd(mailName.length, "*").concat(mailProvider) || "";
};

export const handleCapitalize = (data: string) => {
  return (data || "").charAt(0).toUpperCase() + (data || "").slice(1);
};

export function getBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (err) => reject(err);
  });
}

export function getBinary(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data = (reader?.result as any)?.split(",")[1];
      resolve(atob(data));
    };
    reader.onerror = (err) => reject(err);
  });
}

export const checkOddValue = (data: string) =>
  ["undefined", "null"].includes(data);

export const statusType = {
  success: { col: "!text-[#12B76A]", bg: "!bg-[#ECFDF3]" },
  pass: { col: "!text-[#12B76A]", bg: "!bg-[#ECFDF3]" },
  true: { col: "!text-[#12B76A]", bg: "!bg-[#ECFDF3]" },
  pending: { col: "!text-[#FFBD00]", bg: "!bg-[#FFBD001F]" },
  error: { col: "!text-[#E01020]", bg: "!bg-[#E010201F]" },
  false: { col: "!text-[#E01020]", bg: "!bg-[#E010201F]" },
  fail: { col: "!text-[#E01020]", bg: "!bg-[#E010201F]" },
  info: { col: "!text-[#495057]", bg: "!bg-[#878A991F]" },
};

export const handleTableSelect = (data: any, setData: any, key: string) => ({
  type: "checkbox",
  selectedRowKeys: data,
  onChange: (e: any, selectedRows: any) => {
    const arr: any[] = [];
    selectedRows.forEach((row: any) => arr.push(row?.[key] || row));
    setData(arr);
  },
});

export const handleURL = (
  type: "recap" | "quiz" | "flashcard",
  value: string
) => {
  const urlType = {
    quiz: `Hey! check out my generated quiz on the Nurovant AI app : https://app.nurovant.com/page/quiz/?id=${value}`,
    recap: `Hey! check out my generated recaps on the Nurovant AI app : https://app.nurovant.com/page/recap/?id=${value}`,
    flashcard: `Hey! check out my generated flashcards on the Nurovant AI app : https://app.nurovant.com/page/flashcard/?id=${value}`,
  };
  return urlType?.[type];
};

export function handleDownload(blob: any, name?: string) {
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.download = name || "audio.wav";
  anchor.href = url;
  anchor.click();
}

export const handleAudioConvert = (data: any, callback: any) => {
  const toWav = require("audiobuffer-to-wav");
  const audioContext = new window.AudioContext();
  const fileReader = new FileReader();

  fileReader.onloadend = () => {
    const arrayBuffer = fileReader.result;
    audioContext.decodeAudioData(arrayBuffer as ArrayBuffer, (audioBuffer) => {
      const wav = toWav(audioBuffer);
      const blob = new window.Blob([new DataView(wav)], { type: "audio/wav" });
      callback(blob);
    });
  };

  fileReader.readAsArrayBuffer(data);
};

export const useHandleRecorder = ({
  mimeType,
  onData,
}: {
  mimeType?: MimeType;
  onData?: any;
}) => {
  const [recordState, setRecordState] = useRecoilState(audRecAtom);

  const startRecord = async () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then((stream: any) => {
      // const recorder = new MediaRecorder(stream)
      const recordAudio = new RecordRTCPromisesHandler(stream, {
        type: "audio",
        sampleRate: 16000,
        mimeType: mimeType || "audio/wav",
        // used by StereoAudioRecorder
        // the range 22050 to 96000.
        // let us force 16khz recording:
        desiredSampRate: 16000,
        // MediaStreamRecorder, StereoAudioRecorder, WebAssemblyRecorder
        // CanvasRecorder, GifRecorder, WhammyRecorder
        // recorderType: StereoAudioRecorder,
        // Dialogflow / STT requires mono audio
        numberOfAudioChannels: 1,
        timeSlice: 6000,
        ondataavailable: onData,
      });
      recordAudio.startRecording()
      setRecordState(recordAudio)
    })
    .catch((err: any) => notification.error({
      message: "Error!",
      description: `Error accessing microphone: ${err}`
    }))
  };

  const stopRecord = async () => {
    recordState?.stopRecording(async () => {
      // after stopping the audio, get the audio data
      // return recordState?.getDataURL((audioDataURL: any) => {
      //   return {
      //     audio: {
      //       type: recordState?.getBlob()?.type || "audio/wav",
      //       dataURL: audioDataURL,
      //     },
      //   };
      // });
    });
  };
  return { startRecord, stopRecord };
};

export function convertISOToReadable(dateTimeString: string) {
  const date = new Date(dateTimeString);
  const options: any = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZoneName: "short",
  };
  return date.toLocaleString("en-US", options);
}

export function handleAPIMerge(url: string, path: string, other?: boolean) {
  if (other) return path
  const newPath = path?.slice(1)
  const newUrl = url?.slice(0, -1)
  const isUrlSl = url?.slice(-1) === "/"
  const mainUrl = (isUrlSl ? newUrl : url)
  const isPathSl = (path?.slice(0, 1) === "/")
  const mainPath = (isPathSl ? newPath : path)
  return mainUrl?.concat("/", mainPath)
}

export function handleBase64(data: string) {
  const split = data?.split(",")
  const prefix = "data:audio/wav;base64"
  const isCorrect = isEqual(split?.[0], prefix)
  if(!isCorrect) return [prefix, data]?.join(",")
  return data
}

export const handleArray = (data: any[], other?: any) => ((Array.isArray(data || []) && (data || [])?.length) ? data : (other || []))

export const handleObjToParam = (data: any) => "?".concat(Object.entries(data || {}).map(([k, v]) => String(k).concat("=", String(v))).join("&"))
